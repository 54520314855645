import React from 'react'
import DefaultLink from '@/components/DefaultLink'
import Arrow from '@/assets/Arrow'

export interface ReadMoreProps {
  text: string
  to: string
}

const ReadMore = ({ text, to }: ReadMoreProps) => {
  return (
    <DefaultLink
      to={to}
      className="text-black readmore-link font-FuturaBol text-2xl lg:text-2xl transition-all bg-white rounded-2xl shadow-lg w-full focus:shadow-2xl hover:shadow-2xl flex flex-col p-6 text-center items-center justify-center"
    >
      {text}
      <div className="w-[30px] h-[30px] mt-6">
        <Arrow />
      </div>
    </DefaultLink>
  )
}

export default ReadMore
