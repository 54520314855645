import React from 'react'
import ReadMore from './Readmore'
import BgBanner from '@/components/BgBanner'
import NumberCard from '@/components/NumberCard'

interface numberCards {
  cardtitle: string
  cardvalue: string
}

export interface InformationCaseProps {
  heading: string
  text: string
  BG: any
  link: any
  numberCards?: Array<numberCards>
}

const InformationCase = ({ heading, text, BG, link, numberCards }: InformationCaseProps) => {
  const readmorelink = JSON.parse(link) || null

  return (
    <div className="my-14 md:my-20">
      {heading && <h2 className="text-4xl mb-4 text-black">{heading}</h2>}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-7">
        {text && (
          <div className="col-span-3">
            <BgBanner BG={BG}>{text}</BgBanner>
          </div>
        )}
        {link && (
          <div className="col-span-1 hidden md:flex">
            <ReadMore text={readmorelink?.anchor} to={readmorelink?.url} />
          </div>
        )}
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-3">
        {numberCards?.map((card, index: number) => {
          return <NumberCard key={index} heading={card.cardtitle} value={card.cardvalue} />
        })}
      </div>
      {link && (
        <div className="w-full mt-7 flex md:hidden">
          <ReadMore text={readmorelink?.anchor} to={readmorelink?.url} />
        </div>
      )}
    </div>
  )
}

export default InformationCase
