import React from 'react'
import BackgroundImage from 'gatsby-background-image'

export interface BgBannerProps {
  children: string
  BG?: any
}

const BgBanner = ({ children, BG }: BgBannerProps) => {
  const imageData = BG?.localFile?.childImageSharp?.fluid || null

  return (
    <>
      <div className="rounded-2xl overflow-hidden">
        {imageData ? (
          <BackgroundImage
            alt="bg"
            fluid={imageData}
            preserveStackingContext
            className="text-white pl-5 md:pl-20 pr-5 md:pr-10 py-8 shadow-lg w-full min-h-banner flex bg-no-repeat bg-cover bg-center"
          >
            <div className="flex items-center">
              <p className="text-2xl">{children}</p>
            </div>
          </BackgroundImage>
        ) : (
          <div className="text-white bg-black pl-5 md:pl-20 pr-5 md:pr-10 py-8 shadow-lg w-full min-h-banner flex bg-no-repeat bg-cover bg-center">
            <div className="flex items-center">
              <p className="text-2xl">{children}</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BgBanner
