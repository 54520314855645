import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export interface SquareLinkProps {
  icon?: any
  children?: string
  to?: string
}

const SquareLink = ({ icon, children, to }: SquareLinkProps) => {
  const image = icon && getImage(icon?.localFile)

  return (
    <Link
      to={to}
      className="font-FuturaBol transition-all text-xl lg:text-2xl text-black bg-white focus:shadow-xl hover:shadow-xl hover:scale-[1.02] my-6 p-4 w-[100%] h-[200px] sm:w-[45%] sm:h-[200px] md:w-[140px] md:h-[140px] lg:w-[200px] lg:h-[200px] text-center justify-center items-center rounded-3xl shadow-lg flex flex-col"
    >
      {image && (
        <GatsbyImage
          objectFit="contain"
          className="max-h-[90px] lg:max-w-[140px] bg-white mb-2"
          image={image}
          alt={icon?.altText ? icon?.altText : 'Image'}
        />
      )}
      {children}
    </Link>
  )
}

export default SquareLink
