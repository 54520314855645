import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType, EventType } from '@/types.d'
import TopicsSection from '@/components/TopicsSection'
import InformationCase from '@/components/InformationCase'
import FeaturedItem from '@/components/FeaturedItem'
import Event from '@/components/Event'
import ButtonLink from '@/components/ButtonLink'
import { motion } from 'framer-motion'
import Excerpt from '@/components/Excerpt'
import moment from 'moment'

interface HomeData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      heroTitle?: string
      seo: SeoType
      featuredImage: any
      topics: any
      topicbg: any
      infoblocks: any
      image: any
      showevents: boolean
      eventsHeading: string
      shownews: boolean
      heroFilter: boolean
      newsHeading: string
    }
    posts: {
      nodes: any
    }
    events: {
      nodes: Array<EventType>
    }
    wp: {
      archives: {
        gwEventArchiveUrl: string
        gwArchiveUrl: string
      }
    }
    menu?: any
    quickmenu?: any
  }
}

const HomePageTemplate = ({ data }: HomeData) => {
  // Page data
  const { page, wp, events } = data
  const { menu, quickmenu } = data
  const { posts } = data

  const newsArchive = JSON.parse(wp?.archives?.gwArchiveUrl) || null
  const eventsArchive = JSON.parse(wp?.archives?.gwEventArchiveUrl) || null

  const dateToday = moment().format('YYYYMMDD')

  const nextEvents = events.nodes.filter(event => {
    return Number(event.eventdatenumber) > Number(dateToday)
  })

  const nextEvent = nextEvents?.reverse()[0] || null

  const heroContent = {
    title: page?.title,
    image: page?.image,
    filter: page?.heroFilter,
  }

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      fullWhero={true}
      seo={page?.seo}
      uri={page?.uri}
    >
      {page.topics && (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 1.2 } }}
          viewport={{ once: true }}
        >
          <TopicsSection topics={page.topics} BG={page.topicbg} />
        </motion.div>
      )}
      {posts?.nodes && page?.shownews && (
        <motion.div
          className="my-14"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 1.2 } }}
          viewport={{ once: true }}
        >
          {page?.newsHeading && (
            <div className="mb-4">
              <h2 className="text-4xl">{page?.newsHeading}</h2>
            </div>
          )}
          <div className="grid sm:grid-cols-2 gap-8">
            {posts?.nodes.map((post: any) => {
              return (
                <FeaturedItem
                  key={post?.id}
                  content={post?.title}
                  date={post?.date}
                  to={post?.uri}
                  background={post?.img?.node}
                  featuredType={post?.terms?.nodes}
                />
              )
            })}
          </div>
          {newsArchive && (
            <div className="flex justify-center items-center mt-10">
              <ButtonLink color="gray" to={newsArchive?.url}>
                {newsArchive?.anchor}
              </ButtonLink>
            </div>
          )}
        </motion.div>
      )}
      {page?.infoblocks &&
        page?.infoblocks.map((block: any, index: number) => {
          return (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1, transition: { duration: 1.2 } }}
              viewport={{ once: true }}
            >
              <InformationCase
                heading={block?.title}
                text={block?.text}
                BG={block?.img}
                link={block?.link}
                numberCards={block?.cards}
              />
            </motion.div>
          )
        })}
      {page?.showevents && (
        <motion.div
          className="my-14"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 1.2 } }}
          viewport={{ once: true }}
        >
          {page?.eventsHeading && (
            <div className="mb-[-35px]">
              <h2 className="text-4xl">{page?.eventsHeading}</h2>
            </div>
          )}
          <div>
            {nextEvent && (
              <Event
                key={nextEvent?.id}
                heading={nextEvent?.title}
                date={nextEvent.eventdate}
                enddate={nextEvent?.eventenddate}
                time={nextEvent.eventtime}
                place={nextEvent.eventlocation}
                className="my-20"
              >
                <Excerpt content={nextEvent.content} number={400} removeTables={true} />
              </Event>
            )}
          </div>
          {eventsArchive && (
            <div className="flex justify-center items-center mt-10">
              <ButtonLink color="gray" to={eventsArchive?.url}>
                {eventsArchive?.anchor}
              </ButtonLink>
            </div>
          )}
        </motion.div>
      )}
    </Layout>
  )
}

export const HomePageQuery = graphql`
  query HomePageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      ...seo
      ...featuredHeroImage
      showevents: gwFronpageShowEvents
      eventsHeading: gwFrontpageEventsHeading
      heroFilter: gwShowPostHeaderFilter
      infoblocks: gwFrontpageBlocks {
        cards {
          cardtitle
          cardvalue
        }
        img {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        link
        text
        title
      }
      shownews: gwFrontpageShowNews
      newsHeading: gwFrontpageNewsHeading
      topicbg: gwFrontpageTopicBg {
        altText
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      topics: gwFrontpageTopics {
        link
        img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    posts: allWpPost(limit: 4, sort: { order: DESC, fields: date }) {
      ...allPosts
    }
    wp {
      archives: crbThemeOptions {
        gwEventArchiveUrl
        gwArchiveUrl
      }
    }
    events: allWpGwEvent(limit: 30, sort: { fields: gwEventDateInNumber, order: DESC }) {
      nodes {
        eventdate: gwEventsDate
        eventenddate: gwEventsDateEnd
        eventlocation: gwEventsLocation
        eventtime: gwEventsTime
        eventdatenumber: gwEventDateInNumber
        content
        uri
        title
        id
      }
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default HomePageTemplate
