import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import SquareLink from '../SquareLink'

export interface TopicsSectionProps {
  topics?: any
  BG?: any
}

const TopicsSection = ({ topics, BG }: TopicsSectionProps) => {
  if (!topics) {
    return null
  }

  const imageData = BG?.localFile?.childImageSharp?.fluid || null

  return (
    <div className="rounded-2xl overflow-hidden">
      <BackgroundImage
        alt="bg"
        fluid={imageData}
        preserveStackingContext
        className="bg-banner text-white px-8 lg:px-10 xl:px-20 py-2 shadow-lg w-full flex flex-wrap md:flex-nowrap bg-no-repeat bg-cover bg-center justify-between"
      >
        {topics.map((item: any, index: number) => {
          const link = JSON.parse(item.link) || null
          if (link) {
            return (
              <SquareLink icon={item.img} key={index} to={link.url}>
                {link.anchor}
              </SquareLink>
            )
          }
        })}
      </BackgroundImage>
    </div>
  )
}

export default TopicsSection
