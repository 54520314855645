import React from 'react'

export interface NumberCardProps {
  heading: string
  value: string
  color?: string
}

const NumberCard = ({ heading, value, color = 'blue' }: NumberCardProps) => {
  const bgcolor = color === 'blue' ? 'bg-secondary' : color === 'green' ? 'bg-green' : 'bg-secondary'

  return (
    <section className={`${bgcolor} flex-col flex text-black py-4 px-2 rounded-2xl text-center shadow-lg info-box`}>
      <p className="text-xl xl:text-2xl mb-10 mt-4 font-FuturaBol">{heading}</p>
      <p className="text-4xl xl:text-5xl mb-6 mt-auto tracking-wide leading-10 font-FuturaBol">{value}</p>
    </section>
  )
}

export default NumberCard
